<template>
  <List el="xsm" style="padding: 32px">
    <!-- <Text el="h2">{{ $route.params.id }}</Text> -->
    <List el="column-between" class="v-center">
      <Text el="h3" v-if="referrer"
        >{{ referrer.firstName }} {{ referrer.lastName }}</Text
      >
      <!-- <Text el="h3" v-else>assignment</Text> -->
      <Icon name="close" @click="close" />
    </List>
    <Text el="p" v-if="referrer != null">{{ referrer.notes }}</Text>
    <Accordion v-if="referrer != null">
      <List el="column-between xsm" style="align-items: center">
        <List el="column xsm" style="align-items: center">
          <!-- <Icon name="add" /> -->
          <Text el="h5">More details</Text>
        </List>
        <span class="material-icons"></span>
      </List>
      <List el="xxsm">
        <Text el="p"
          >Name: {{ referrer.firstName }} {{ referrer.lastName }}</Text
        >
        <Text el="p">Email: {{ referrer.email }}</Text>
        <Text el="p">Phone: {{ referrer.phone }}</Text>
        <Text el="p">Created by: {{ referrer.createdBy.displayName }}</Text>
        <Text el="p">Created on: {{ referrer.createdOn }}</Text>
        <Text el="p">ID: {{ referrer.id }}</Text>
      </List>
    </Accordion>
    <br>
    <List el="xxsm" v-if="referrer != null">
      <Text el="p">Status: {{ referrer.status }}</Text>
      <Text el="p">Referral Code: {{ referrer.id }}</Text>

      <Text el="p">Students Referred: {{ referrer.referredCountSET }}</Text>
      <Text el="p">Cost/Referral: ${{ referrer.perStudentPayoutSET }}</Text>
      <Text el="p">Lifetime Earnings: {{ referrer.lifetimeEarnings }}</Text>
      <Text el="p">Paid Earnings: {{ referrer.paidEarnings }}</Text>
      <Text el="p"
        >Unpaid Earnings:
        {{ referrer.lifetimeEarnings - referrer.paidEarnings }}</Text
      >
      <Text el="p"
        ><b>SET Link: </b>https://alphaleaf.tech/forms/set/application/?ref={{
          referrer.id
        }}</Text
      >
      <Text el="p"
        ><b>Stats Link: </b>https://alphaleaf.tech/referrer/?ref={{
          referrer.id
        }}</Text
      >
    </List>

    <List
      el="column-stretch xsm"
      style="margin-top: 32px"
      v-if="referrer != null && $store.getters.isAdmin"
    >
      <!-- <Btn el="2ry" @click="deleteReferrer(referrer.id)">Delete</Btn> -->
      <Btn
        @click="setStatus(referrer.id, 'inactive')"
        v-if="referrer.status == 'active'"
        >Deactivate</Btn
      >
      <Btn
        @click="setStatus(referrer.id, 'active')"
        v-if="referrer.status == 'inactive'"
        >Activate</Btn
      >
    </List>
    <!-- <List el="column-stretch xsm">
      <Btn el="2ry">Delete</Btn>
      <Btn>Add Files</Btn>
    </List> -->
  </List>
</template>
<script>
// let startsWith = require("lodash/startsWith");
// let forEach = require("lodash/forEach");
import router from "../router";
import { db } from "@/firebase";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
// import {
//   getStorage,
//   ref, deleteObject
// } from "firebase/storage";
import Icon from "@/components/btn/Icon.vue";

// const storage = getStorage();

export default {
  components: {
    Icon,
  },
  data() {
    return {
      window: this.$store.state.main.window,
      user: this.$store.state.main.user,
      main: this.$store.state.main.layout.main,
      section: this.$store.state.main.layout.section,
      referrer: null,
    };
  },
  watch: {
    "$route.params.id": function () {
      this.getReferrer();
    },
  },
  methods: {
    async getReferrer() {
      let routeParams = this.$route.params.id;
      if (routeParams != null) {
        const docRef = doc(db, "referrers", this.$route.params.id);
        const docSnap = await getDoc(docRef);
        let data = docSnap.data();
        data.id = docSnap.id;
        this.referrer = data;
      }
    },
    close() {
      this.closeSection();
      router.push("/dashboard/referrals");
    },
    closeSection() {
      this.section.collapse = true;
    },
    async setStatus(id, status) {
      const docRef = doc(db, "referrers", id);
      // Set the "capital" field of the city 'DC'
      await updateDoc(docRef, {
        status: status,
      });
      this.closeSection();
      this.getReferrer();
    },
    async deleteReferrer(id) {
      let x = this;
      var deletePrompt = prompt('To confirm please type "delete"');

      if (deletePrompt != null && deletePrompt == "delete") {
        await deleteDoc(doc(db, "referrers", id)).catch(() => {
          // Uh-oh, an error occurred!
          alert(`Unable to delete referrer. Please notify your webmaster.`);
        });
        x.$store.commit("referrals/deleteReferrer", id);
        x.close();
      }
    },
  },
  mounted() {
    this.getReferrer();
  },
};
</script>
<style lang="scss" scoped>
.tags {
  display: flex;
  flex-wrap: wrap;
}
.tags > * {
  font-size: 12px;
  margin: 3px;
  padding: 3px 13px;
  /* border: 1px solid var(--color-7); */
  justify-self: flex-start;
  border-radius: 100px;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  color: var(--color-65);
  -webkit-text-fill-color: currentColor;
}
.tags > .active {
  color: var(--color-white);
  background-color: var(--color-cloud);
}
.file {
  padding: 4px 8px;
  border-radius: 5px;
  display: grid;
  // background-color: var(--color-sky-5);
  grid-auto-flow: column;
  align-items: center;
  text-decoration: none;
  grid-gap: var(--space-xxsm);
  transition: 0.2s;
  justify-content: flex-start;
  font-weight: bold;
}
.file:hover {
  background-color: var(--color-sky-5);
}
</style>
